import Vue from 'vue';
import App from './App.vue';
import router from './router.js';
import store from './vuex';
import 'aframe';
import 'aframe-html-shader';
import 'aframe-physics-system/dist/aframe-physics-system';
import 'aframe-extras';
// analytics
import VueGtag from 'vue-gtag';
Vue.use(
  VueGtag, {
    config: { id: 'G-VERH6ZF937' }
  },
  router
);

Vue.config.productionTip = false;

// a-frameのタグでVue CLIの警告を出さない
Vue.config.ignoredElements = [
  'a-scene',
  'a-entity',
  'a-camera',
  'a-cursor',
  'a-box',
  'a-sky',
  'a-plane',
  'a-assets',
  'a-marker',
  'a-marker-camera',
  'a-image',
  'a-video',
  'a-text',
  'a-light',
  'a-cone',
  'a-sphere'
];

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');
