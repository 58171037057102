function detectTouchDevice() {
  // タッチデバイスか判定
  const isTouch = 'ontouchstart' in window || navigator.maxTouchPoints > 0;
  // マウスやタッチパッドのようなポインティングデバイスがあるか確認
  const hasFinePointer = matchMedia('(pointer: fine)').matches;
  if (navigator.userAgent.match(/Windows NT/i) && isTouch && hasFinePointer) {
      return false; // Surface PCのようなデバイスはPCとして扱う
  }
  return isTouch;
}

function initialState () {
  return {
    firstAccess: true,  // 初回アクセスフラグ
    bgmStatus: false,    // BGM許可ステータス(true:許可、false:拒否)
    isTouchDevice: detectTouchDevice()  // タッチデバイス判定結果
  }
}

const state = initialState();
const getters = {
  // 初回アクセスフラグの取得
  getFirstAccess: (state) => state.firstAccess,
  // BGM許可ステータスの取得
  getBgmStatus: (state) => state.bgmStatus,
  // タッチデバイス判定の取得
  getTouchDeviceStatus: (state) => state.isTouchDevice
}

const mutations = {
  // 初回アクセスフラグの更新
  setFirstAccess(state, flag) {
    state.firstAccess = flag;
  },
  // BGM許可ステータスの設定
  setBgmStatus(state, bgmStatus) {
    state.bgmStatus = bgmStatus;
  },
  // タッチデバイス判定の設定
  setTouchDeviceStatus(state, isTouchDevice) {
      state.isTouchDevice = isTouchDevice;
  }
}

const actions = {
  // 初回アクセスフラグの更新
  setFirstAccess({commit, dispatch}, flag) {
      commit('setFirstAccess', flag);
  },
  // BGM許可ステータスの設定
  setBgmStatus({commit, dispatch}, bgmStatus) {
    commit('setBgmStatus', bgmStatus);
  },
  // タッチデバイス判定の設定
  setTouchDeviceStatus({ commit }) {
      commit('setTouchDeviceStatus', detectTouchDevice());
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}