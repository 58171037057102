import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate'
import CampusStatus from './campus/status';

Vue.use(Vuex);
export default new Vuex.Store({
  modules: {
    CampusStatus
  },
  plugins: [createPersistedState(
    {
      key: 'vuex',
      paths: [
        'CampusStatus.firstAccess', // 初回アクセスフラグ
        'CampusStatus.bgmStatus',    // BGM許可ステータス
        'CampusStatus.isTouchDevice'    // タッチデバイス判定
      ],
      storage: window.sessionStorage
    }
  )]
});
